import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import ActionCable from "actioncable"

import $ from 'jquery';
window.jQuery = window.$ = $; 
import "bootstrap";

document.addEventListener('DOMContentLoaded', () => {
  if ($('#chat').length < 1) {
    return;
  }
  
  const cable = ActionCable.createConsumer("/cable");

  new Vue({
    el: '#chat',
    data: {
      message_groups: [],
      messages: [],
      selectedMessageGroup: null,
      movieModal: false,
      comments: [],
      selectedMovie: null,
      movieUrl: null,
      patientId: null,
      filename: null
    },
    created() {
      this.fetchMessageGroups();
    },
    updated() {
      let div = $('.direct-chat-messages')[0];
      div.scrollTop = div.scrollHeight;
      if (this.selectedMessageGroup == null) {
        let li = $('.message-list li')[0];
        $(li).click();
      }
    },
    methods: {
      fetchMessageGroups() {
        axios.get(`${location.pathname}.json`).then(response => {
          this.message_groups = response.data;
        });
      },
      fetchMessages(event) {
        let id = $(event.currentTarget).data('id')
        this.selectedMessageGroup = id;
        $('.js-message').removeClass('silver');
        $(event.currentTarget).addClass('silver');
        this.reloadMessages(id);
        if (cable.subscriptions['subscriptions'].length > 1) {
          cable.subscriptions.remove(cable.subscriptions['subscriptions'][1])
        }
        let that = this;
        cable.subscriptions.create({
          channel: 'ChatChannel',
          message_group_id: id
        }, {
          received(data) {
            that.reloadMessages(id)
          }
        });

      },
      reloadMessages(id) {
        axios.get(`${location.pathname}/${id}.json`).then(response => {
          this.messages = response.data;
        });
      },
      submitMessage() {
        let id = this.selectedMessageGroup;
        if ($('#js-comment').val() == '') {
          return false;
        }
        axios({
          url: `${location.pathname}/${id}.json`, 
          method: 'patch',
          data: $('#comment-form').serialize(),
          withCredentials: true
        }).then(response => {
          $('#js-comment').val('');
          this.reloadMessages(id);
        });
      },
      fetchMovie(event) {
        let id = $(event.currentTarget).data('id')
        this.selectedMovie = id;
        this.reloadMovie(id, true);
        $('#chat_movie-modal').modal('show');
        if (cable.subscriptions['subscriptions'].length > 1) {
          cable.subscriptions.remove(cable.subscriptions['subscriptions'][1])
        }
        let that = this;
        cable.subscriptions.create({
          channel: 'MovieChannel',
          movie_id: id
        }, {
          received(data) {
            that.reloadMovie(id, false)
          }
        });

        return false;
      },
      reloadMovie(id, isRefreshMovie) {
        axios.get(`movies/${id}.json`).then(response => {
          let data = response.data;
          this.comments = data.comments;
          if (isRefreshMovie) {
            this.movieUrl = data.movie_url;
          }
          this.patientId = data.patient_id;
          this.filename = data.filename;
        });
      },
      submitComment() {
        let id = this.selectedMovie
        if ($('#js-comment').val() == '') {
          return false;
        }
        axios({
          url: `${location.pathname}/${id}.json`, 
          method: 'patch',
          data: $('#comment-form').serialize(),
          withCredentials: true
        }).then(response => {
          $('#js-comment').val('');
          this.reloadMovie(id, false);
        });
      }
    },
    computed: {
    }
  });
});

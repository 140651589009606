import axios from 'axios'
import $ from 'jquery';
window.jQuery = window.$ = $; 
import "bootstrap";
import select2 from 'select2/dist/js/select2.full.min';

document.addEventListener('DOMContentLoaded', () => {
  if ($('#chat').length < 1) {
    return;
  }
  $('.select2').select2({
    debug: true
  })
})

import Vue from "vue/dist/vue.esm";
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
import axios from "axios";
import ActionCable from "actioncable";

import $ from "jquery";
window.jQuery = window.$ = $;
import "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  };

  if ($("#movie").length < 1) {
    return;
  }

  const cable = ActionCable.createConsumer("/cable");
  new Vue({
    el: "#movie",
    data: {
      id: null,
      comments: [],
      selectedMovie: null,
      movieUrl: null,
      wmvDownloadUrl: null,
      mp4DownloadUrl: null,
      patientId: null,
      datetime: null,
      latitude: null,
      longitude: null,
      degree_str: "",
      cataract_estimation_str: "-",
      acd_estimation_str: "-",
      filename: null,
      client_name: null,
    },
    created() {
      let that = this;
    },
    mounted() {
      if (this.pathMovieIndex()) {
        this.fetchMovie(this.pathMovieIndex());
      }

      $("#movie-modal").on("hidden.bs.modal", () => {
        this.setPathname(null);
      });

      window.addEventListener("popstate", () => {
        if (this.pathMovieIndex()) {
          this.fetchMovie(this.pathMovieIndex());
        } else {
          $("#movie-modal").modal("hide");
        }
      });
    },
    updated() {
      // let div = $('.direct-chat-messages')[0];
      // div.scrollTop = div.scrollHeight;
      $('[data-toggle="tooltip"]').tooltip();
    },
    methods: {
      onMovieClick(event) {
        let id = $(event.currentTarget).data("id");
        this.setPathname(id);
        this.fetchMovie(id);
      },
      setPathname(id) {
        if (id) {
          let path = this.apiMoviePath + "/" + id;
          window.history.pushState({}, "", path);
        } else {
          window.history.pushState({}, "", this.apiMoviePath);
        }
      },
      fetchMovie(id) {
        this.selectedMovie = id;
        this.reloadMovie(id, true);
        $("#movie-modal").modal("show");
        if (cable.subscriptions["subscriptions"].length > 1) {
          cable.subscriptions.remove(cable.subscriptions["subscriptions"][1]);
        }
        let that = this;
        cable.subscriptions.create(
          {
            channel: "MovieChannel",
            movie_id: id,
          },
          {
            received(data) {
              that.reloadMovie(id, false);
            },
          }
        );

        return false;
      },
      reloadMovie(id, isRefreshMovie) {
        axios.get(`${this.apiMoviePath}/${id}.json`).then((response) => {
          let data = response.data;
          this.messages = response.data;
          this.comments = data.comments;
          if (isRefreshMovie) {
            this.movieUrl = data.movie_url;
            this.wmvDownloadUrl = `/client/movies/${id}/download?extension=wmv`;
            this.mp4DownloadUrl = `/client/movies/${id}/download?extension=mp4`;
          }
          this.clientId = data.client_id;
          this.patientId = data.patient_id;
          this.datetime = data.datetime;
          this.latitude = data.latitude;
          this.longitude = data.longitude;
          this.degree_str = data.degree_str;
          this.cataract_estimation_str = data.cataract_estimation_str;
          this.acd_estimation_str = data.acd_estimation_str;
          this.filename = data.filename;
          this.client_name = data.client_name;
        });
      },
      submitComment() {
        let id = this.selectedMovie;
        if ($("#js-comment").val() == "") {
          return false;
        }
        axios({
          url: `${this.apiMoviePath}/${id}.json`,
          method: "patch",
          data: $("#comment-form").serialize(),
          withCredentials: true,
        }).then((response) => {
          $("#js-comment").val("");
          this.reloadMovie(id, false);
        });
      },
      deleteMovie(e) {
        let id = this.selectedMovie;
        if (confirm("Are you sure to delete this movie?")) {
          axios({
            url: `${this.apiMoviePath}/${id}`,
            method: "delete",
            withCredentials: true,
          }).then((response) => {
            $("#movie-modal").modal("hide");
            this.setPathname(null);
            window.location.reload();
          });
        }
      },
      editMovie() {
        window.open("/admin/movies/" + this.selectedMovie + "/edit", "_blank");
      },
      editMovieForClient() {
        window.open("/client/movies/" + this.selectedMovie + "/edit", "_blank");
      },
      editable_by(client_id) {
        return this.clientId == client_id;
      },
      pathMovieIndex() {
        const tokens = location.pathname.split("movies/");
        if (
          tokens.length >= 2 &&
          tokens[1].length > 0 &&
          parseInt(tokens[1]) > 0
        ) {
          return parseInt(tokens[1]);
        }
      },
    },
    computed: {
      googleMapUrl() {
        return (
          "https://www.google.com/maps?q=" +
          this.latitude +
          "," +
          this.longitude
        );
      },
      apiMoviePath() {
        const path = location.pathname.split("movies")[0] + "movies";
        return path;
      },
    },
  });
});
